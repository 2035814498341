
  import { mapState } from 'vuex'

  export default {
    name: `MySecuritySettings`,
    props: {
      value: {
        type: Object,
        default: null
      },
      formDataSettings: {
        type: Object,
        default: null
      }
    },
    data() {
      return {
        changPwdUrl: `${this.$config.public.baseApi}/change_password`,
        confirmDelModal: false,
        confirmDelLocalStorage: false
      }
    },
    computed: {
      isCandidate() {
        return this.$store.getters[`permissions/userIsCandidate`]
      },
      dataExist() {
        return !localStorage.getItem(`aeq-data`)
      },
      ...mapState({
        double_auth: (state) =>
          state.settings.me.security.double_auth || state.formData.settings.two_factor_device_types[0].value,
        deletion: (state) => state.settings.me.deletion,
        list_2fa: (state) => state.formData.settings.two_factor_device_types,
        canResetPassword: (state) => state.settings.me.security.can_reset_password
      })
    },
    watch: {
      settings: {
        deep: true,
        handler() {
          this.$emit(`input`, this.settings)
        }
      }
    },
    created() {},
    methods: {
      async deleteAccount() {
        await this.$store.dispatch(`settings/deleteAccount`)
        this.closeConfirmDelModal()
      },
      async inputBlur(event, { key, namespace = `security` }) {
        console.log(event, event?.target?.type)
        const value =
          event?.target?.type === `text`
            ? event.target.value
            : typeof event === `string` || typeof event === `number`
            ? event
            : ``
        await this.$store.commit(`settings/SET_VALUE`, { namespace, key, value })
      },
      closeConfirmDelModal() {
        this.confirmDelModal = false
      },
      openConfirmDelModal() {
        this.confirmDelModal = true
      },
      removeLocalData() {
        this.$store.dispatch(`screenings/delScreeningLocal`)
        this.closeConfirmDelLocalStorageModal()
      },
      closeConfirmDelLocalStorageModal() {
        this.confirmDelLocalStorage = false
      },
      openConfirmDelLocalStorageModal() {
        this.confirmDelLocalStorage = true
      },
      setErrors(errors) {
        if (!!errors) {
          this.$refs.securityObs.setErrors(errors)
        }
      }
    }
  }
